export function humanTime(time) {
  const [totalSeconds, ms] = time.toFixed(3).split('.');
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}:${ms.padStart(3, '0')}`;
}

export function shuffle(array) {
  let currentIndex = array.length;
  let randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function random(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function getURLSearchParam(key) {
  return new URLSearchParams(location.search).get(key);
}

export function calcDistance(x1, y1, x2, y2) {
    const x = Math.abs(x1 - x2);
    const y = Math.abs(y1 - y2);

    return Math.abs(Math.sqrt(x * x + y * y));
}

export function calcAngle(x1, y1, x2, y2) {
    const x = Math.abs(x1 - x2);
    const y = Math.abs(y1 - y2);
    const z = Math.sqrt(x * x + y * y);

    return Math.round(Math.asin(y / z) / Math.PI * 180);
}
