/* global createjs */

import { humanTime, calcDistance } from './utils.js';

createjs.Ticker.framerate = 60;

export default {
    initialized: false,
    loader: new createjs.LoadQueue(false, null, true),
    stage: new createjs.Stage('stage'),
    score: 0,
    preload(onFileLoad) {
        this.loader.installPlugin(createjs.Sound);

        return new Promise((resolve) => {
            this.loader.on('fileload', function(e) {
                onFileLoad && onFileLoad(e.target.progress);
            });

            this.loader.on('fileerror', console.log);

            this.loader.on('complete', resolve);

            this.loader.loadManifest([
                'bigshadow_success.png',
                'bigshadow_fail.png',
                'frame_fail.png',
                'frame_success.png',
                'tip_failed.png',
                'tip_excellent.png',
                'arrow_hd.svg',
                'arrow_bd.svg',
                'scale_rule.png',
            ].map((src) => ({ src, id: src.split('.')[0], type: createjs.Types.IMAGE })), true, 'img/game/');

            this.loader.loadManifest([
                'bead_1.png',
                'bead_2.png',
                'bead_3.png',
                'bead_4.png',
                'bead_5.png',
                'bead_6.png',
                'bead_7.png',
            ].map((src) => ({ src, id: src.split('.')[0], type: createjs.Types.IMAGE })), true, 'img/');

            this.loader.loadManifest([
                'success.mp3',
                'fail.mp3',
            ].map((src) => ({ src, id: src, type: createjs.Types.SOUND })), true, 'audio/');
        })
    },
    init(config) {
        if (this.initialized) return;

        this.initialized = true;
        this.onOver = config?.onOver;

        const loader = this.loader;
        const stage = this.stage;
        const canvas = stage.canvas;
        const W = 750;

        canvas.width = W;
        canvas.height = canvas.width * window.innerHeight / window.innerWidth;

        // const bg = new createjs.Bitmap(loader.getResult('bg'));
        // bg.scale = Math.max(1, canvas.height / bg.image.height);
        // stage.addChild(bg);

        stage.addChild(new createjs.Shape(new createjs.Graphics().beginFill('rgba(0,0,0,0.01)').drawRect(0, 0, canvas.width, canvas.height)));

        this.bigShadowItems = new createjs.Container();
        stage.addChild(this.bigShadowItems);

        const items = new createjs.Container();
        this.items = items;
        stage.addChild(items);

        const rule = new createjs.Bitmap(loader.getResult('scale_rule'));
        rule.name = 'rule';
        rule.regX = rule.image.width / 2;
        rule.regY = rule.image.height;
        rule.x = canvas.width / 2;
        rule.y = canvas.height - 190 * 2;
        this.rule = rule;
        stage.addChild(rule);

        const arrowHd = new createjs.Bitmap(loader.getResult('arrow_hd'));
        arrowHd.name = 'arrow_hd';
        arrowHd.regX = arrowHd.image.width / 2;
        arrowHd.rotation = 0;
        this.arrowHd = arrowHd;
        stage.addChild(arrowHd);

        const arrowBd = new createjs.Bitmap(loader.getResult('arrow_bd'));
        arrowBd.name = 'arrow_bd';
        arrowBd.regX = arrowBd.image.width / 2;
        arrowBd.regY = arrowBd.image.height;
        arrowBd.x = canvas.width / 2;
        arrowBd.y = rule.y - 50 * 2;
        arrowBd.rotation = 0;
        this.arrowBd = arrowBd;
        stage.addChild(arrowBd);

        const timeText = new createjs.Text(humanTime(0), '400 40px Anton monospace, monospace', '#FFFFFF');
        timeText.x = canvas.width / 2
        timeText.y = 84;
        timeText.textBaseline = 'top';
        timeText.textAlign = 'center';
        stage.addChild(timeText);
        this.timeText = timeText;

        const frameFail = new createjs.Bitmap(loader.getResult('frame_fail'));
        frameFail.name = 'frameFail';
        frameFail.scaleX = canvas.width / frameFail.image.width;
        frameFail.scaleY = canvas.height / frameFail.image.height;
        frameFail.visible = false;
        stage.addChild(frameFail);

        const frameSuccess = new createjs.Bitmap(loader.getResult('frame_success'));
        frameSuccess.name = 'frameSuccess';
        frameSuccess.scaleX = canvas.width / frameSuccess.image.width;
        frameSuccess.scaleY = canvas.height / frameSuccess.image.height;
        frameSuccess.visible = false;
        stage.addChild(frameSuccess);

        const tipExcellent = new createjs.Bitmap(loader.getResult('tip_excellent'));
        tipExcellent.name = 'tipExcellent';
        tipExcellent.regX = tipExcellent.image.width / 2;
        tipExcellent.regY = 0;
        tipExcellent.x = canvas.width / 2;
        tipExcellent.y = 144;
        tipExcellent.visible = false;
        stage.addChild(tipExcellent);

        const tipFailed = new createjs.Bitmap(loader.getResult('tip_failed'));
        tipFailed.name = 'tipFailed';
        tipFailed.regX = tipFailed.image.width / 2;
        tipFailed.regY = 0;
        tipFailed.x = canvas.width / 2;
        tipFailed.y = tipExcellent.y;
        tipFailed.visible = false;
        stage.addChild(tipFailed);

        createjs.Touch.enable(stage);
        
        this.reset();
    },
    reset() {
        $('#gamePage').removeClass('active-1 active-2');
        $('#gamePage .ft .item.active').removeClass('active');

        this.items.removeAllChildren();
        this.bigShadowItems.removeAllChildren();

        this.score = [];
        this.arrowDeg = 0;
        this.arrowDir = 1;
        this.penaltyTime = 0;
        this.duration = 0;
        this.timeText.text = humanTime(0);

        this.updateArrow();
        this.stage.update();
    },
    start(targetIds) {
        this.targetIds = targetIds;
        this.stopped = false;
        this.ticker = createjs.Ticker.on('tick', this.tick, this);

        this.addItems(1, 1.3, [1, 2, 3, 4, 5, 6, 7]);
        this.addItems(2, -1.2, [7, 6, 5, 4, 3, 2, 1]);
        this.addItems(3, 1, [3, 4, 5, 2, 7, 1, 6]);

        // this.pressmoveListener = this.stage.on('pressmove', (e) => {
        //     if (this.stopped) return;

        //     const centerX = this.stage.canvas.width / 2;
        //     const ruleY = this.rule.getTransformedBounds().y;

        //     if (e.stageY > ruleY) return;

        //     let angle = calcAngle(centerX, ruleY, e.stageX, e.stageY);

        //     angle = (90 - angle) * (e.stageX < centerX ? -1 : 1);

        //     this.arrowBd.rotation = this.arrowHd.rotation = angle;
        //     this.updateArrow();
        // });

        this.pressupListener = this.stage.on('pressup', () => {
            if (!this.stopped) this.fireArrow();
        });

        const startTime = Date.now();
        this.countdownTimer = setInterval(() => {
            this.duration = (Date.now() - startTime) / 1e3;
            this.totalDuration = this.duration + this.penaltyTime;
            this.timeText.text = humanTime(this.totalDuration);
        }, 10);
    },
    restart(targetIds) {
        this.reset();
        this.start(targetIds || this.targetIds);
    },
    stop() {
        clearInterval(this.countdownTimer);

        this.stopped = true;

        // this.stage.off('pressmove', this.pressmoveListener);
        this.stage.off('pressup', this.pressupListener);
        createjs.Ticker.off('tick', this.ticker);
    },
    over() {
        this.stop();

        if (this.onOver) this.onOver(this.totalDuration);
    },
    tick(event) {
        if (!this.stopped) {
            this.arrowDeg += 1 * this.arrowDir;

            if (this.arrowDeg >= 45 || this.arrowDeg <= -45) {
                this.arrowDir *= -1;
            }

            this.arrowBd.rotation = this.arrowHd.rotation = this.arrowDeg;
            this.updateArrow();

            this.updateItems();
        }

        this.stage.update(event);
    },
    addItems(row, speed, ids) {
        ids.forEach((id, i) => {
            this.addItem(id, row, i + 1, speed);
        });
    },
    addItem(type, row, col, speed) {
        const item = new createjs.Bitmap(this.loader.getResult(`bead_${type}`));

        item.beadId = type;
        item.row = row;
        item.col = col;
        item.speed = speed;
        if (speed > 0) {
            item.x = (col - 1) * (this.stage.canvas.width * .5);
        } else {
            item.x = this.stage.canvas.width - (col - 1) * (this.stage.canvas.width * .5);
        }
        item.y = (225 / 1448 * this.stage.canvas.height) + (190 / 1448 * this.stage.canvas.height) * (row - 1);
        item.scaleX = 140 / item.image.width;
        item.scaleY = 140 / item.image.height;
        item.shadow = new createjs.Shadow('rgba(0,0,0,.8)', 0, 24, 24);

        item.hitArea = new createjs.Shape(new createjs.Graphics().beginFill('red').drawCircle(item.image.width / 2, item.image.height / 2, item.image.width * .7));

        const bigShadow = new createjs.Bitmap(this.loader.getResult('bigshadow_success'));
        bigShadow.x = item.x - 118;
        bigShadow.y = item.y - 118;
        bigShadow.visible = false;
        item.bigShadow = bigShadow;
        this.bigShadowItems.addChild(bigShadow);

        this.items.addChild(item);
    },
    fireArrow() {
        this.stopped = true;

        const getTargetedBead = (idx) => {
            const angleRadians = Math.abs(this.arrowBd.rotation) * (Math.PI / 180);
            const bead = this.items.getChildAt(idx);
            const AB = this.arrowBd.y - (bead.getTransformedBounds().y + bead.getTransformedBounds().height / 2);
            const BC = AB * Math.tan(angleRadians);

            const x = this.arrowBd.x + (this.arrowBd.rotation > 0 ? BC : -BC);
            const y = this.arrowBd.y - AB;
            const item = this.items.getObjectUnderPoint(x, y);

            if (item) {
                const itemBounds = item.getTransformedBounds();
                if (itemBounds.x > this.stage.canvas.width || itemBounds.x + itemBounds.width < 0) return;
            }

            return item;
        }

        const nearest = getTargetedBead(14) || getTargetedBead(7) || getTargetedBead(0);
        let success = !!nearest;

        if (nearest) {
            const itemBounds = nearest.getTransformedBounds();
            const ruleBounds = this.rule.getTransformedBounds();
            const distance = calcDistance(this.stage.canvas.width / 2, ruleBounds.y, nearest.x + itemBounds.width / 2, nearest.y + itemBounds.height / 2);

            nearest.distance = distance;

            if (nearest.got || !this.targetIds.includes(nearest.beadId) || this.score.includes(nearest.beadId)) {
                success = false;
            }
        }

        let scaleY;

        if (success) {
            console.log('success', nearest);

            this.score.push(nearest.beadId);

            this.nearest = nearest;

            scaleY = nearest.distance / this.arrowBd.image.height;
        } else {
            console.log(nearest ? 'wrong' : 'miss');
 
            this.penaltyTime += 2;

            scaleY = (nearest ? nearest.distance : this.rule.getTransformedBounds().y - this.items.getChildAt(0).getTransformedBounds().y) / this.arrowBd.image.height;
        }

        console.log('scaleY', scaleY);

        if (nearest) {
            nearest.got = true;
            nearest.bigShadow.image = this.loader.getResult(success ? 'bigshadow_success' : 'bigshadow_fail');

            createjs.Tween
                .get(nearest)
                .wait(scaleY * 150 + 300)
                .to({ alpha: 0 }, 300)
                .set({ visible: false });

            createjs.Tween
                .get(nearest.bigShadow)
                .set({ visible: true })
                .to({ alpha: 0 }, 0)
                .wait(scaleY * 150)
                .to({ alpha: 1 }, 300)
                .to({ alpha: 0 }, 300)
                .set({ visible: false });
        }

        createjs.Tween.get(this.arrowBd, {
            onChange: this.updateArrow.bind(this),
        })
            .to({ scaleY }, scaleY * 150)
            .call(() => {
                createjs.Sound.play(`${success ? 'success' : 'fail'}.mp3`);

                if (success) {
                    $('#gamePage').removeClass('active-1').addClass(`active-${this.score.length}`);
                    $(`#gamePage .ft .item .bead[data-id="${nearest.beadId}"]`).parent().addClass('active');
                }
            })
            .to({ scaleY: 1 }, scaleY * 150);

        const frame = this.stage.getChildByName(success ? 'frameSuccess' : 'frameFail');
        createjs.Tween
            .get(frame)
            .set({ visible: true })
            .to({ alpha: 0 }, 0)
            .wait(scaleY * 150)
            .to({ alpha: 1 }, 300)
            .to({ alpha: 0 }, 300)
            .set({ visible: false });

        const tip = this.stage.getChildByName(success ? 'tipExcellent' : 'tipFailed');
        createjs.Tween
            .get(tip, {
                onComplete: () => {
                    if (success) {
                        if (this.score.length === 2) {
                            this.over();
                        } else {
                            this.stopped = false;
                        }
                    } else {
                        this.stopped = false;
                    }
                },
            })
            .set({ visible: true })
            .to({ scale: .5, alpha: 0 }, 0)
            .wait(scaleY * 150)
            .to({ scale: 1, alpha: 1 }, 200)
            .wait(200)
            .to({ scale: .5, alpha: 0 }, 200)
            .set({ visible: false });
    },
    updateArrow() {
        function calculateTriangleSides(angleA, lengthAB) {
            const angleARadians = angleA * (Math.PI / 180);
            const w = lengthAB * Math.sin(angleARadians);
            const h = lengthAB * Math.cos(angleARadians);

            return { w, h };
        }

        const { w, h } = calculateTriangleSides(this.arrowBd.rotation, this.arrowBd.image.height * this.arrowBd.scaleY);

        this.arrowHd.x = this.stage.canvas.width / 2 + w;
        this.arrowHd.y = this.rule.getTransformedBounds().y + 25 - h;
    },
    updateItems() {
        this.items.children.forEach(function(item) {
            const width = item.getTransformedBounds().width;

            item.x += -2 * item.speed;

            item.bigShadow.x = item.x - 118;

            if (item.speed > 0 && item.x + width + 30 < 0) {
                item.x = this.stage.canvas.width * 3.5 - width;
            } else if (item.speed < 0 && item.x > this.stage.canvas.width + 30) {
                item.x = this.stage.canvas.width - (this.stage.canvas.width * 3.5);
            }
        }, this);
    },
};