import { humanTime, shuffle, getURLSearchParam } from './utils.js';
import game from './game.js';

const OFFLINE_SHOP_CODE = getURLSearchParam('shop');
const debug = location.port === '5757';

if (debug) {
  window.game = game;
}

if (OFFLINE_SHOP_CODE) {
  $('.for-online').remove();

  // $('#orderPopup .titlebox .title').text(shop.name);
  // $('#orderPopup .address .value').text(shop.address);
  $('#orderPopup [name="shop_code"]').val(OFFLINE_SHOP_CODE);
}

const stagesData = {};
let orderInfo;
let USER = {
  nickname: '',
};
let userCoord = {
  latitude: 39.917839,
  longitude: 116.397029,
};
let pca;
let STAGES = 1;
const beadMap = {
  1: { name: '乘风领航', path: 'pages/pdt/pdt-detail/pdt-detail?code=94594C-24GG-00&chan_id=1555' },
  2: { name: '永恒守护', path: 'pages/pdt/pdt-detail/pdt-detail?code=94592C-24GG-00&chan_id=1553' },
  3: { name: '四叶心语', path: 'pages/pdt/pdt-detail/pdt-detail?code=94740C-24GG-00&chan_id=1551' },
  4: { name: '信勇前行', path: 'pages/pdt/pdt-detail/pdt-detail?code=94591C-24GG-00&chan_id=1552' },
  5: { name: '隽永力量', path: 'pages/pdt/pdt-detail/pdt-detail?code=94593C-24GG-00&chan_id=1554' },
  6: { name: '睿智之星', path: 'pages/pdt/pdt-detail/pdt-detail?code=94595C-24GG-00&chan_id=1556' },
  7: { name: '心心相印', path: 'pages/pdt/pdt-detail/pdt-detail?code=95038C-24GG-00&chan_id=1550' },
};
const beadsGroupList = [
  { id: 1, name: '一路生花', beads: [4, 3], rope: '' },
  { id: 2, name: '智勇双全', beads: [6, 4], rope: '1' },
  { id: 3, name: '坚贞不渝', beads: [2, 7], rope: '' },
  { id: 4, name: '一往无前', beads: [1, 4], rope: '1' },
];
const landingBeadGroupList = shuffle(beadsGroupList);
const beadGroup = landingBeadGroupList[landingBeadGroupList.length - 1];
$('#homePage .items, #gamePage .ft').addClass(`type-${beadGroup.rope}`);

beadGroup.beads.forEach((id, idx) => {
  [$('#homePage .item'), $('#guidePopup .item'), $('#gamePage .item'), $('#resultPage .inner .result .item')].forEach(($item) => {
    $item.eq(idx).find('.bead').attr('src', `img/bead_${id}.png`).attr('data-id', id);
    $item.eq(idx).find('.name').text(beadMap[id].name);
    $item.eq(idx).find('.wx-open-weapp').attr('path', beadMap[id].path);
  });
});

$('#resultPage .result').attr('data-beads', beadGroup.id);

let $lastPage;
$.fn.showPage = function() {
  $lastPage = $('.page.active').removeClass('active');

  track('pv', this.attr('id'));

  return this.addClass('active');
}

$.fn.showPopup = function() {
  if (this.attr('id') === 'cityPopup' && !pca) {
    $.getJSON('js/pca.json').then((data) => {
      pca = data;

      const html = Object.keys(data).map(v => `<li>${v}</li>`).join('');

      $('#cityPopup .wheel.province').html(html);
    });
  }
  return this.addClass('active');
}

$.fn.hidePopup = function() {
  return this.removeClass('active');
}

$('select').change(function() {
  $(this).toggleClass('placeholder', !this.value);
}).trigger('change');

$('#date').change(function() {
  $(this).prev('input').val(this.value);

  getTimeList();
});

function getTimeList() {
  api('GET', 'reserve/periodQuota', {
    date: $('#orderPopup [name="date"]').val(),
    shop_code: $('#orderPopup [name="shop_code"]').val(),
  }).then((data) => {
    const timeSelect = $('#time')[0];

    timeSelect.value = '';
    timeSelect.options.length = 1;

    $(timeSelect).triggerHandler('change');

    Object.entries(data.period).forEach(function([key, count]) {
      if (count) timeSelect.add(new Option(key));
    });
  });
}

// https://apifox.com/apidoc/shared-d588a275-de6e-4dee-9390-dfc7bdb70c35?pwd=ump8ihm9
function api(method, endpoint, data) {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: method,
      url: `${debug ? 'http://192.168.31.28:3001' : ''}/api/${endpoint}`,
      data: method === 'GET' ? data : JSON.stringify(data),
      contentType: method === 'GET' ? null : 'application/json; charset=utf-8',
      dataType: 'json',
      success: (data) => {
        if (!debug && data.errcode === 14003) {
          location.replace(`/api/login?scope=snsapi_userinfo&redirect=${encodeURIComponent(location.href)}`);
          return;
        }

        if (data.errcode === 0) {
          resolve(data.data);
        } else {
          reject(data.errmsg);
        }
      },
      error: (xhr, status, error) => {
        reject(error || status);
      },
    });
  });
}

function initHomePage() {
  const $titleValue = $('#homePage .titlebox .title .value');
  const $wrap = $('<div class="wrap"></div>');
  let html = '';

  landingBeadGroupList.map(function(item) {
    html += `<span class="word">${item.name}</span>`;
  });

  $wrap
    .html(html + html)
    .one('animationend', function() {
      setTimeout(() => {
        $('#homePage').removeClass('loading');
      }, 500);
    })
    .appendTo($titleValue);
}

function showAlert(msg) {
  $('#msgPopup .content').text(msg);
  $('#msgPopup').showPopup();
}

function track(type, label) {
  console.log('[track]', type, label);

  api('GET', 'index/statistics', {
    event_type: type,
    event_value: label,
  });
}

$(document).on('click', '[data-track]', function(e) {
  track('click', e.currentTarget.dataset.track || e.currentTarget.textContent.trim());
});

$('#btnStart').click(function() {
  if (!$('#homePage .agreement .checked').length) {
    showAlert('请勾选同意活动政策');
    return;
  }

  $('#homePage .items').addClass('zoom');

  setTimeout(() => {
    $('#guidePopup').showPopup();
    $('#homePage .items').removeClass('zoom');
  }, 600);
});

$('.btnGetPrize').click(function() {
  $('#prizePopup').showPopup();
});

$('#orderPopup .agreement').click(function() {
  const checked = $(this).find('.checkbox').toggleClass('checked').hasClass('checked');
  localStorage[checked ? 'setItem' : 'removeItem']($(this).data('agreement'), Date.now());
});

$('.agreement a').click(function(e) {
  e.stopPropagation();
  $(e.target.getAttribute('href')).showPopup();
});

$('.rule-link').click(function() {
  $('#rulePopup').addClass('disonly').showPopup();
});

$('.popup .close').click(function() {
  $(this).closest('.popup').removeClass('active');
});

function getNearestShop() {
  api('GET', 'reserve/nearestShop', userCoord).then((data) => {
    $('#orderPopup .titlebox .title').text(data.name);
    $('#orderPopup .address .value').text(data.address);
    $('#orderPopup [name="shop_code"]').val(data.code);
  })
}

$('#btnOrder').click(function() {
  $('#orderPopup').showPopup();

  if (debug) {
    userCoord = {
      latitude: 31.300115,
      longitude: 121.515868,
    };

    getNearestShop();
  } else {
    wx.getLocation({
      type: 'wgs84',
      success: function ({ longitude, latitude }) {
        userCoord = {
          latitude,
          longitude,
        };
        
        console.log(userCoord);

        getNearestShop();
      },
      fail: function() {
        getNearestShop();
      },
    });
  }
});

$('#orderPopup .field-shop').click(function() {
  $('#addressPopup').showPopup();

  if (!$('#addressPopup .scroller').children().length) {
    getShopList();
  }
});

$('#orderPopup .primary').click(function() {
  if (this.disabled) return;

  const form = $('#orderPopup form')[0];

  if (!form.elements.shop_code.value) {
    showAlert('请选择预约分店');
    return;
  }

  if (!$('#orderPopup .agreement .checked').length) {
    showAlert('请勾选同意隐私与Cookie政策');
    return;
  }

  if (!form.elements.name.value) {
    showAlert('请输入预约姓名');
    return;
  }

  if (!/^1\d{10}$/.test(form.elements.phone.value)) {
    showAlert('请输入预约手机号');
    return;
  }

  if (!form.elements.date[0].value) {
    showAlert('请选择日期');
    return;
  }

  if (!form.elements.time.value) {
    showAlert('请选择时间');
    return;
  }

  this.disabled = true;

  api('POST', 'reserve/submit', {
    shop_code: form.elements.shop_code.value,
    name: form.elements.name.value,
    phone: form.elements.phone.value,
    date: form.elements.date[0].value,
    period: form.elements.time.value,
  })
    .then((data) => {
      orderInfo = data;
      $('#btnOrder').hide();
      $('#btnOrdered').show();
      $('#orderPopup').hidePopup();
      $('#orderResultPopup').showPopup();
      drawOrderPoster();
    })
    .catch(showAlert)
    .then(() => {
      this.disabled = false;
    });
});

$('#btnOrdered').click(function() {
  $('#orderResultPopup').showPopup();
  drawOrderPoster();
});

$('#prizePopup .field-province, #prizePopup .field-city').click(function() {
  $('#cityPopup').showPopup().one('change', (e, province, city) => {
    $('#prizePopup .field-province input').val(province);
    $('#prizePopup .field-city input').val(city);
  });
});

function getShopList(city) {
  return api('GET', 'reserve/shopList', {
    ...userCoord,
    city,
  }).then((data) => {
    const $items = data.map((row) => $(`
      <div class="item">
          <div class="name">${row.name}</div>
          <div class="info-item">
              <span class="icon icon-location"></span>
              <span class="value">${row.address}</span>
          </div>
          <div class="info-item">
              <span class="icon icon-clock"></span>
              <span class="value">10:00-22:00</span>
          </div>
          ${row.distance ? `
          <div class="info-item distance">
              <span class="icon icon-map2"></span>
              <span class="value">${(row.distance / 1e3).toFixed(1)}KM</span>
          </div>
          ` : ''}
          <a class="info-item phone" href="tel:${row.phone}">
              <span class="icon icon-call"></span>
              <span class="value">${row.phone}</span>
          </a>
          <div class="actions">
              <button type="button" data-track data-action="map"><span class="icon icon-map"></span>查看地图</button>
              <button type="button" data-track data-action="contact"><span class="icon icon-message"></span>珠宝顾问</button>
              <button type="button" data-track data-action="order"><span class="icon icon-calendar-tick"></span>预约到店</button>
          </div>
      </div>
    `).data('shop', row));

    $('#addressPopup .scroller').empty().append($items).scrollTop(0);
  });
}

$('#addressPopup .city').click(function() {
  $('#cityPopup').showPopup().one('change', (e, province, city) => {
    $('#addressPopup .city .value').text(`${province} ${city}`);

    getShopList(city === '市辖区' ? province : city);
  });
});

$('#addressPopup .scroller').on('click', '.item', function() {
  $(this).addClass('selected').siblings('.selected').removeClass('selected');
});

$('#addressPopup .scroller').on('click', '[data-action]', function(e) {
  const data = $(this).closest('.item').data('shop');

  switch (e.target.dataset.action) {
    case 'map':
      wx.openLocation({
        latitude: data.latitude,
        longitude: data.longitude,
        name: data.name,
        address: data.address,
      });
      break;
    case 'contact':
      $(this).closest('.item').find('.info-item.phone')[0].click();
      break;
    case 'order':
      setTimeout(() => $('#addressPopup .btnConfirm').click());
      break;
  }
});

$('#addressPopup .btnConfirm').click(function() {
  const data = $('#addressPopup .item.selected').data('shop');

  $('#orderPopup [name="shop_code"]').val(data.code);
  $('#orderPopup [name="shop"]').val(data.name);
  $('#orderPopup #date').prop('min', data.start_date).prop('max', data.end_date);

  getTimeList();
});

$('#prizePopup .btnConfirm').click(function() {
  const form = $('#prizePopup form')[0];

  if (!form.elements.name.value) {
    showAlert('请输入收件人姓名');
    return;
  }

  if (!/^1\d{10}$/.test(form.elements.phone.value)) {
    showAlert('请输入收件人手机号');
    return;
  }

  if (!form.elements.province.value) {
    showAlert('请选择省份');
    return;
  }

  if (!form.elements.city.value) {
    showAlert('请选择城市');
    return;
  }

  if (!form.elements.address.value) {
    showAlert('请填写详细地址');
    return;
  }

  $('#prizePopup').hidePopup();
  $('#orderResultPopup').showPopup();
  drawOrderPoster();
});

$('#scorePopup input')
  .focus(function() {
    setTimeout(() => {
      $('.nickname-bar').css('bottom', window.innerHeight - window.visualViewport.height).show();
    }, 500);
  })
  .blur(function() {
    setTimeout(() => {
      $('.nickname-bar').hide();
    }, 200);
  });

$('#scorePopup .primary').click(function() {
  if (this.disabled) return;

  const nickname = $('#scorePopup input').val();

  if (nickname) {
    $('#orderPopup input[name="name"], #prizePopup input[name="name"]').val(nickname);

    const time = $('#scorePopup').data('time');

    this.disabled = true;

    api('POST', 'online/submit', {
      score: time * 1e3,
      name: nickname,
      beads: beadGroup.id,
    }).then((data) => {
      $('#scorePopup').hidePopup();
      $('#resultPage').showPage();

      $('#resultPage .result').attr('data-beads', data.beads);

      $('#resultPage .scores .score .value').text(humanTime(data.score / 1e3));
      $('#resultPage .scores .rank .value .n, #prizePopup .detail .rank').text(data.rank || '-');

      $('#resultPage .scores').toggleClass('got', data.in_rank_stage);

      if (data.in_rank_stage) {
        showAlert(`您已在赛段${'一二三四'[data.in_rank_stage-1]}的比赛中获得过排名奖励
    无法再次参与排名`);
      }

      saveStagesData(data);
      updateStage(CURRENT_STAGE);

    // showAlert(`您本次的成绩存在异常
    // 请重新参与`);
    }).catch((err) => {
      showAlert(err);
    }).then(() => {
      this.disabled = false;
    });
  } else {
    showAlert('请输入昵称');
  }
});

$('.nickname-bar').click(function() {
  $('#scorePopup input').val(USER.nickname);
});

$('.exit').click(function() {
  window.close();
  wx.closeWindow();
});

$('#rulePopup .agree').click(function() {
  $('#rulePopup').hidePopup();
  $('#homePage .agreement .checkbox').addClass('checked');

  if ($('#homePage').hasClass('loading')) {
    initHomePage();
  }

  localStorage.setItem('rule', Date.now());
});

$('#privacyPopup .agree, #cookiePopup .agree').click(function() {
  $(this).closest('.popup').hidePopup();
  $('#orderPopup .agreement .checkbox').addClass('checked');

  localStorage.setItem('privacy', Date.now());
});

if (localStorage.getItem('privacy')) {
  $('.agreement .checkbox').addClass('checked');
}

$('#guidePopup .primary').click(function() {
  $('#guidePopup').hidePopup();

  game.start(beadGroup.beads);
  $('#gamePage').showPage();
});

$('#gamePage .btn-back').click(function() {
  game.stop();
  $('#homePage').showPage();
});

$('.scroll-content').scroll(function(e) {
  const progress = Math.max(0, Math.min(1, e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight)));

  $(this).next('.scroll-bar').find('.thumb').css('top', `${progress * 100}%`);
});

$('#wallpaperPage .beads .bead').click(function() {
  const $empty = $('#wallpaperPage .empty').eq(0);

  if ($empty.length) {
    $empty.find('.box').html(`<img class="pic" src="img/bead_${this.dataset.id}.png" />`);
    $empty.find('.name').text(beadMap[this.dataset.id].name);
    $empty.find('.remove').data('id', this.dataset.id);
    $empty.data('id', this.dataset.id).removeClass('empty');
  }
});

$('#wallpaperPage .remove').click(function() {
  const $slot = $(this).parent();

  $slot.find('.box').empty();
  $slot.find('.name').text('请选择');
  $slot.addClass('empty');
});

$('#wallpaperPage .btnConfirm').on('click', function() {
  const $selected = $('#wallpaperPage .selected .item:not(.empty)');

  if ($selected.length === 2) {
    const ids = $selected.map(function () {
      return $(this).data('id');
    }).get();

    ids.sort();

    $('#posterPage .poster').attr('src', `img/wallpaper/${ids.join('')}.jpg`);
    $('#posterPage').showPage();
  } else {
    showAlert('请选择钻运珠');
  }
});

$('#btnRebuild').on('click', function() {
  $('#wallpaperPage .selected .empty .remove').click();
  $('#wallpaperPage').showPage();
});

$('.btnBackResult').on('click', function() {
  $('#resultPage').showPage();
});

$('#posterPage .poster').on('contextmenu', function() {
  track('save', '壁纸');
});

$('#orderResultPopup .poster').on('contextmenu', function() {
  track('save', '预约海报');
});

$('#sharePopup .poster').on('contextmenu', function() {
  track('save', '分享海报');
});

if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
  $('.poster').on('touchstart', function(e) {
    this.startPos = this.endPos = e.originalEvent.touches[0].pageX + e.originalEvent.touches[0].pageX;
  }).on('touchmove', function(e) {
    this.endPos = e.originalEvent.changedTouches[0].pageX + e.originalEvent.changedTouches[0].pageX;
  }).on('touchend', function() {
    if (this.startPos === this.endPos) {
      $(this).triggerHandler('contextmenu');
    }
  });
}

const $toplistPage = $('#resultPage .toplist-inner');
let CURRENT_STAGE = STAGES;

function getRankItem(row, me) {
  const [, month, day] = row.score_time ? row.score_time.match(/\d+/g) : [];

  return `
  <li class="item rank-${row.rank} ${me ? 'me' : ''}">
      <span class="rank">${row.rank ? `<span class="no">No.</span><span class="value">${row.rank}</span>` : '<span class="line"></span>'}</span>
      <img class="icon" src="img/gift.png" alt="" />
      <span class="name">${row.name || '用户昵称'}</span>
      <span class="date">${row.score_time ? `${month}/${day}日完成` : '<span class="line"></span>'}</span>
      <span class="time">${row.rank ? humanTime(row.score / 1e3) : '<span class="line"></span>'}</span>
  </li>`;
}

function getToplist() {
  // $toplistPage.find('.toplist.my-rank').html(getRankItem({}, true));

  api('GET', 'online/rankinglist', {
    stage: CURRENT_STAGE,
  }).then((data) => {
    const html = data.list.map(row => getRankItem(row, row.rank === data.rank)).join('');

    $toplistPage.find('.scroll-view .toplist').empty().append(html);

    $toplistPage.find('.toplist.my-rank').html(getRankItem(Object.assign({ rank: data.rank }, data.game || {}), true));

    $toplistPage.find('.tip').text(`赛段${'一二三四'[CURRENT_STAGE-1]}最终排行将于${data.deadline}公布`);
  });
}

$('#resultPage').on('touchstart', function(e) {
  this.startX = this.endX = e.originalEvent.touches[0].pageX;
  this.startY = this.endY = e.originalEvent.touches[0].pageY;
}).on('touchmove', function(e) {
  this.endX = e.originalEvent.changedTouches[0].pageX;
  this.endY = e.originalEvent.changedTouches[0].pageY;
}).on('touchend', function() {
  if (Math.abs(this.startY - this.endY) > Math.abs(this.startX - this.endX)) {
    if (this.startY - this.endY > 40) {
      $(this).addClass('show-toplist');

      if (!$('.toplist-inner .scroll-view .toplist li').length) {
        getToplist();
      }
    }
  } else {
    if (Math.abs(this.startX - this.endX) > 40) {
      updateStage((CURRENT_STAGE + (this.startX > this.endX ? 1 : -1)) % STAGES);
    }
  }
});

$('#resultPage .btnToplistBack').on('click', function() {
  $('#resultPage').removeClass('show-toplist');
});

for (let i = 1; i < STAGES; i++) {
  $('.stage-hd .dots').prepend(`<div class="dot">
    <span class="icon-checkbox"></span>
    <span class="icon-checked"></span>
</div>`);
}

function saveStagesData(data) {
  const { previous, ...rest } = data;

  stagesData[STAGES] = rest;

  previous.forEach((row) => {
    row.hasPrize = row.stage !== 0 && row.stage === data.in_rank_stage;
    stagesData[row.stage] = row;
  });
}

function updateStage(stage) {
  CURRENT_STAGE = stage === 0 ? STAGES : stage;

  const stageData = stagesData[CURRENT_STAGE];
  const stageText = '一二三四'[CURRENT_STAGE-1];

  const $resultHd = $('#resultPage .inner .stage-hd');
  $resultHd.find('.stage .text').text(`赛段${stageText}`);
  $resultHd.find('.dots .dot.active').removeClass('active');
  $resultHd.find('.dots .dot').eq(CURRENT_STAGE - 1).addClass('active');

  $('#resultPage .scores .stage').text(stageText);

  const $toplistHd = $toplistPage.find('.stage-hd');
  $toplistHd.find('.stage .text').text(`赛段${stageText}·排行榜`);
  $toplistHd.find('.daterange').text(['09.13 - 09.20', '09.20 - 10.04', '10.04 - 10.18', '10.18 - 11.01'][CURRENT_STAGE-1]);
  $toplistHd.find('.dots .dot.active').removeClass('active');
  $toplistHd.find('.dots .dot').eq(CURRENT_STAGE - 1).addClass('active');

  $('#resultPage .scores').toggleClass('got', stageData.rank === 0);
  $('#resultPage .result').attr('data-beads', stageData.beads);
  $('#resultPage .scores .score .value').text(humanTime(stageData.score / 1e3));
  $('#resultPage .scores .rank .value .n, #prizePopup .detail .rank').text(stageData.rank || '-');

  if (stageData.hasPrize) {
    $('#btnGetPrize').show();
    // $('#resultPage .scroll-tip .text').text('上滑查看摘星排行榜，解锁排名奖励');
  } else {
    $('#btnGetPrize').hide();
    // $('#resultPage .scroll-tip .text').text('上滑查看摘星排行榜');
  }

  getToplist();
}

$('#resultPage .stage-hd').find('.stage .arrow').on('click', function(e) {
  if (STAGES === 1) return;

  const next = e.target.classList.contains('next');

  updateStage((CURRENT_STAGE + (next ? 1 : -1)) % STAGES);
});

$('#homePage .redo').on('click', function() {
  location.reload();
});

function drawSharePoster() {
  const canvas = $('#sharePopup canvas')[0];
  const ctx = canvas.getContext('2d');
  const bgImg = new Image();
  const stageData = stagesData[CURRENT_STAGE];

  bgImg.onload = function() {
    canvas.width = bgImg.width;
    canvas.height = bgImg.height;
    ctx.drawImage(bgImg, 0, 0);

    ctx.scale(2, 2);
    ctx.fillStyle = '#FFFFFF';

    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';

    ctx.font = '900 40px HarmonyOS Sans SC, sans-serif';
    ctx.fillText(beadsGroupList.find(b => b.id == stageData.beads).name, 60, 950);

    ctx.font = '900 40px Anton, sans-serif';
    ctx.fillText(humanTime(stageData.score / 1e3), 62, 1060);
    ctx.fillText('No. 2', 62, 1175);

    ctx.font = '400 20px sans-serif';
    ctx.textAlign = 'right';
    ctx.fillText('长按屏幕扫描二维码扫码', 685, 1168);
    ctx.fillText(`即刻与@${USER.nickname}一同挑战钻运摘星，解锁限量惊喜`, 685, 1168 + 24);

    $('#sharePopup .poster').attr('src', canvas.toDataURL('image/jpeg'));
  }

  bgImg.src = `img/poster/${beadGroup.id}.jpg`;
}
// setTimeout(drawSharePoster, 1e3);

function drawOrderPoster() {
  const canvas = $('#orderResultPopup canvas')[0];
  const ctx = canvas.getContext('2d');
  const bgImg = new Image();

  bgImg.onload = function() {
    canvas.width = bgImg.width;
    canvas.height = bgImg.height;
    ctx.drawImage(bgImg, 0, 0);

    ctx.scale(2, 2);
    ctx.fillStyle = '#FFFFFF';

    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';

    ctx.font = '400 28px sans-serif';
    ctx.fillText(orderInfo.date, 65, 838);
    ctx.fillText(orderInfo.address, 65, 947);

    ctx.textAlign = 'right';
    ctx.fillText(orderInfo.time, 685, 838);

    $('#orderResultPopup .poster').attr('src', canvas.toDataURL('image/jpeg'));
  }

  bgImg.src = 'img/poster/order.jpg';
}
// drawOrderPoster();

$('.btnWallpaper, #btnWallapper').on('click', function() {
  $('#wallpaperPage').showPage();
});

$('.btnScore').on('click', function() {
  $('#resultPage').showPage();
});

$('.btnShop').on('click', function() {
  location.href = 'https://cn.chowsangsang.com/';
});

$('.btnShare').on('click', function() {
  $('#sharePopup').showPopup();
  drawSharePoster();
});

$('.btnReplay').on('click', function() {
  game.restart(beadGroup.beads);
  $('#gamePage').showPage();
});

$('.btnBack').on('click', function() {
  $lastPage.showPage();
});

$('#wx-subscribe-btn').on('success', function(e) {
  track('subscribe', '打开通知');
  console.log(JSON.parse(e.originalEvent.detail.subscribeDetails));
}).on('error', function(e) {
  console.log(e.originalEvent);
  alert(e.originalEvent.detail.errMsg);
});

$('.wx-open-weapp').on('launch', function(e) {
  track('launch', e.originalEvent.detail.path);
  console.log(JSON.parse(e.originalEvent.detail));
}).on('error', function(e) {
  console.log(e.originalEvent);
  alert(e.originalEvent.detail.errMsg);
});

if ($('.page.active:not(#homePage)').length === 0) {
  if (localStorage.getItem('rule')) {
    initHomePage();
  } else {
    $('#rulePopup').removeClass('disonly').showPopup();
  }
} else {
  $('#homePage').remove();
}

const bgm = document.getElementById('bgm');
$('.btn-sound').click(function () {
  const on = $(this).toggleClass('on').hasClass('on');

  on ? bgm.play() : bgm.pause();
});

game.preload((progress) => {
  console.log(progress);
}).then(() => {
  game.init({
    onOver(time) {
      $('#scorePopup').data('time', time);

      if (stagesData[STAGES] && stagesData[STAGES].score) {
        $('#scorePopup input').val(USER.nickname);
        $('#scorePopup .primary').click();
      } else {
        $('#scorePopup').showPopup();
      }
    },
  });
});

if (bgm.paused) {
  $(document).one('touchend', function() {
    bgm.play();
    $('.btn-sound').addClass('on');
  });
}

api('GET', 'user/info').then((data) => {
  Object.assign(USER, data);

  if (USER.nickname) {
    $('.nickname-bar .val').text(USER.nickname);
    $('#orderPopup input[name="name"], #prizePopup input[name="name"]').val(USER.nickname);
  }

  if (USER.phone) $('input[name="phone"]').val(USER.phone);

  api('GET', 'reserve/info').then((data) => {
    orderInfo = data;
    $('#btnOrder').hide();
    $('#btnOrdered').show();
  });

  api('GET', 'online/state').then((data) => {
    STAGES = CURRENT_STAGE = data.stage;

    api('GET', 'online/score').then((data) => {
      saveStagesData(data);
      updateStage(CURRENT_STAGE);

      if (data.score || data.previous.length) {
        $('#homePage .btns').css('display', 'flex');
      }
    });
  });
});

$('#cityPopup .back').on('click', function() {
  if ($('#cityPopup .wheel.province').is(':visible')) {
    $('#cityPopup').hidePopup();
  } else {
    $('#cityPopup .wheel.province').show();
    $('#cityPopup .wheel.city').hide();
  }
});

$('#cityPopup .wheel.province').on('click', 'li', function() {
  const province = $(this).text();
  const html = pca[province].map((value) => `<li>${value}</li>`).join('');

  $('#cityPopup').data('province', province);
  $('#cityPopup .wheel.city').html(html);
  $('#cityPopup .wheel.province').hide();
  $('#cityPopup .wheel.city').show();
});

$('#cityPopup .wheel.city').on('click', 'li', function() {
  const province = $('#cityPopup').data('province');
  const city = $(this).text();

  $('#cityPopup').hidePopup();
  $('#cityPopup .wheel.province').show();
  $('#cityPopup .wheel.city').hide();
  $('#cityPopup').trigger('change', [province, city]);
});

const shareData = {
  title: '即刻与我一同挑战Charme钻运摘星，解锁限量惊喜',
  desc: '',
  link: location.href,
  imgUrl: `${location.origin}/img/share.jpg`,
  success(res) {
    track('share', res.errMsg);
  }
};

api('GET', 'jssdk/getConfig', {
  url: location.href.split('#')[0],
})
  .then((data) => {
      data.jsApiList = ['updateTimelineShareData', 'updateAppMessageShareData', 'getLocation', 'openLocation', 'closeWindow'];
      data.openTagList = ['wx-open-subscribe', 'wx-open-launch-weapp'];

      wx.ready(() => {
        wx.updateTimelineShareData(shareData);
        wx.updateAppMessageShareData(shareData);
      });

      wx.error(function(res) {
        alert(res.errMsg);
      });

      wx.config(data);
});

track('h5', 'enter');

$(document).on('visibilitychange', function() {
  if (document.visibilityState === 'hidden') {
    const data = new Blob([JSON.stringify({
      event_type: 'h5',
      event_value: 'leave',
    })], {
      type: 'application/json',
    });

    navigator.sendBeacon('/api/index/statistics', data);
  }
});
